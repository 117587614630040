import { defineStore } from 'pinia';

export const useDropdownStore = defineStore('dropdown', {
    state: () => {
        return {
            openDropdowns: [],
        };
    },

    getters: {
        dropdownOpen() {
            return this.openDropdowns.length > 0;
        }
    },

    actions: {
        toggleDropdown(dropdown) {
            const index = this.openDropdowns.indexOf(dropdown);
            if (index === -1) {
                this.openDropdowns.push(dropdown);
            } else {
                this.openDropdowns.splice(index, 1);
            }
        },
        openDropdown(dropdown) {
            this.openDropdowns.push(dropdown);
        },
        closeDropdown(dropdown) {
            const index = this.openDropdowns.indexOf(dropdown);
            if (index !== -1) {
                this.openDropdowns.splice(index, 1);
            }
        }
    }
});
